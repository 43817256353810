import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { set, ref, push, onValue, update, get, remove } from "firebase/database";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Smart AI Firebase keys
const firebaseConfig = {
  appId: process.env.REACT_APP_APP_ID,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
// export const storage = getStorage(app);

// For firebase authentification
// export const auth = getAuth(app);
// export const provider = new GoogleAuthProvider(app);

// CRUD operations in firebase

// Create
export const createOnFirebase = async ({ path, value }) => {
  try {
    const key = push(ref(db, path)).key;
    await set(ref(db, `${path}/${key}`), value);
    return key;
  } catch (error) {
    console.log("Error in creating on firebase");
  }
};

// Sync a var with firebase
export const useSyncWithFirebase = ({ path, setFunction, setIsLoading = null }) => {
  useEffect(() => {
    // If not path return
    if (!path || !setFunction) return;

    // Setting Loading to true
    setIsLoading && setIsLoading(true);

    const pathRef = ref(db, path);
    return onValue(pathRef, (snapshot) => {
      try {
        setFunction(snapshot.val());
      } catch (error) {
        console.log(`Error syncing with ${path}`, error);
      } finally {
        // Setting Loading to false
        setIsLoading && setIsLoading(false);
      }
    });
  }, [path, setFunction, setIsLoading]);
};

// Read data only once
export const readOnceFromFirebase = async ({ path }) => {
  try {
    const snapshot = await get(ref(db, path));
    return snapshot.val();
  } catch (error) {
    console.log("Error reading from Firebase:", error);
  }
};

// Update on firebase
// Use updates[`path`] = value;
export const updateOnFirebase = async ({ updates }) => {
  try {
    await update(ref(db), updates);
  } catch (error) {
    console.log("Error updating Firebase:", error);
  }
};

// Remove Or Update to null
export const removeFromFirebase = async ({ path }) => {
  try {
    await remove(ref(db, path));
  } catch (error) {
    console.log("Error removing from Firebase:", error);
  }
};

// Generates a push key at specific path
export const getPushKeyFromFirebase = ({ path }) => {
  try {
    const key = push(ref(db, path)).key;
    return key;
  } catch (error) {
    console.log("Error generating push Key from firebase");
  }
};

// SINGLE UPDATE ON FIREBASE ( FASTER THAN UPDATE SOMETIMES ) --------------------------------
export const singleUpdateOnFirebase = async ({ path, value }) => {
  try {
    const updates = {};
    updates[path] = value;
    updateOnFirebase({ updates });
  } catch (error) {
    console.log("Error in singleUpdateOnFirebase");
  }
};
