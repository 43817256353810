import { singleUpdateOnFirebase } from "./firebase";
import { getNormalDateTime } from "../functions/dateFunctions";

// Function to generate or get a unique user ID
export function getUserID() {
  let userId = localStorage.getItem("uniqueUserID");
  if (!userId) {
    userId = `user_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
    localStorage.setItem("uniqueUserID", userId);
  }
  return userId;
}

// Track Click
export const trackClick = async (buttonName = "") => {
  const userId = getUserID();

  if (buttonName) {
    singleUpdateOnFirebase({
      path: `websiteTracking/${userId}/${buttonName}`,
      value: getNormalDateTime(Date.now()),
    });
  }
};
