// Constants used in entire website

// Livorn URL Play Store
export const playStoreUrl =
  "https://play.google.com/store/apps/details?id=company.cubes.livorn&hl=en&gl=US";

// Livorn URL App Store
export const appStoreUrl = "https://apps.apple.com/us/app/hosh-ai-be-aware-be-better/id6738334288";

// Gyaan AI URL Play Store
export const gyaanPlayStoreUrl =
  "https://play.google.com/store/apps/details?id=company.cubes.gyaan&hl=en&gl=US";

// Whatsapp Url
export const whatsappCommunityUrl = "https://chat.whatsapp.com/KjVCXDIu3Ym2Kt2xtQyIvT";

// Contact Email on the website, later change to contact@cubes.company
export const contactEmail = "lvlupharshit@gmail.com";

// Is Mobile Device Check
export const isMobileDevice = window.innerWidth <= 768;

// Is Tab Device
export const isTabDevice = window.innerWidth >= 768 && window.innerWidth <= 1024;

// Livorn Video Url
export const videoUrl =
  "https://firebasestorage.googleapis.com/v0/b/livorn-website.appspot.com/o/Hosh%20AI%20Vertical%20Video.mp4?alt=media&token=1f88696e-fbaa-497b-952a-4c9b8c859edb";

// Support Whatsapp Url
export const whatsappSupportUrl =
  "https://api.whatsapp.com/send/?phone=919588795279&text=Hello%2C%20I%20need%20premium%20of%20Livorn";

// Linkedin
export const harshitLinkedin = "https://www.linkedin.com/in/lvlupharshit/";
export const mukulLinkedin = "https://www.linkedin.com/in/mukulrathee/";
