// Constants Used
export const months = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December",
};

export const shortMonths = Object.keys(months);
// const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

// Get short Month by index of the month
export const getShortMonth = (index) => {
  return shortMonths[index];
};

// Returning Date Object if input is not
const checkInputDate = (inputDate) => {
  return new Date(inputDate);
  // if (typeof inputDate == "string" || typeof inputDate == "number") {
  // }
  // if (inputDate instanceof Date) {
  //   return inputDate;
  // }
};

// Get date like "25 Mar 2023"
export const getNormalDate = (inputDate, includeYear = true) => {
  let date = checkInputDate(inputDate);
  const myDate = date.getDate();
  const myMonth = getShortMonth(date.getMonth());
  const myYear = date.getFullYear().toString();
  return `${myDate} ${myMonth}${includeYear ? " " + myYear : ""}`;
};

// Get time in format "7:30 am"
export const getNormalTime = (inputDate) => {
  let date = checkInputDate(inputDate);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

// Get Normal Date and Time like "25 Mar 2023 - 7:30 am"
export const getNormalDateTime = (inputDate) => {
  return `${getNormalDate(inputDate)} - ${getNormalTime(inputDate)}`;
};
