import React from "react";
import { trackClick } from "../utils/tracking";
import PrimaryButton from "../components/PrimaryButton";
import { appStoreUrl, gyaanPlayStoreUrl, playStoreUrl } from "../utils/Constants";

const DownloadAppPage = ({ app }) => {
  // Android
  const handleAndroidPress = () => {
    app === "Gyaan" && window.open(gyaanPlayStoreUrl, "_blank");

    if (app === "Hosh") {
      window.open(playStoreUrl, "_blank");
      trackClick("playStoreDownload");
    }
  };

  // IOS
  const handleIOSPress = () => {
    app === "Gyaan" && window.open(gyaanPlayStoreUrl, "_blank");

    if (app === "Hosh") {
      trackClick("appStoreDownload");
      window.open(appStoreUrl, "_blank");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "90vh",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: 100,
      }}
    >
      <div
        style={{
          width: "90%",
          height: 300,
          backgroundColor: "#44444490",
          borderRadius: 30,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../assets/PlaystoreIcon.png")}
          style={{ width: 100, height: 100, marginBottom: 20, marginLeft: 20 }}
          alt="Logo"
        />
        <h4 style={{ fontSize: 20 }}>Google Playstore</h4>
        <PrimaryButton title={`Download ${app} AI`} onClick={handleAndroidPress} />
      </div>
      <div
        style={{
          width: "90%",
          height: 300,
          backgroundColor: "#44444490",
          borderRadius: 30,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: 50,
        }}
      >
        <img
          src={require("../../src/images/AppStoreIcon.png")}
          style={{ width: 76, height: 92, marginBottom: 20, marginLeft: 20 }}
          alt="Logo"
        />
        <h4 style={{ fontSize: 20 }}>Apple App Store</h4>
        <PrimaryButton title={`Download ${app} AI`} onClick={handleIOSPress} />
      </div>
    </div>
  );
};

export default DownloadAppPage;
